@media screen and (min-width: 1280px) {
    .public_header {
        height: 400px;
        background: #000;
        position: relative;
        .public_header_title {
            width: 1110px;
            position: absolute;
            left: 50%;
            top: 200px;
            font-weight: 500;
            font-size: 30px;
            color: #FFFFFF;
            line-height: 32px;
            transform: translateX(-50%);
            z-index: 9;
        }
        &:before {
            display: block;
            content: "";
            position: absolute;
            z-index: 3;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            opacity: 0.4;
            background-color: #000;
        }
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .public_box {
        width: 1110px;
        margin: 0 auto 50px;
    }
    .public_menu {
        display: flex;
        height: 60px;
        align-items: center;
        margin-bottom: 16px;
        img {
            margin-right: 37px;
            cursor: pointer;
        }
    }
    .about_FCCC_content {
        text-align: center;
        padding-bottom: 64px;
    }
    .line_box {
        width: 100%;
        padding: 0 90px;
        .line {
            width: 300px;
            height: 1px;
            background: #000;
        }
    }
    
    .article_wrap {
        padding: 60px 90px 0;
        overflow: hidden;
        .article_item_box {
            margin-bottom: 60px;
            .article_item_title {
                font-weight: 800;
                font-size: 35px;
                color: #000000;
                line-height: 34px;
                margin-bottom: 40px;
            }
            .article_item_content {
                margin-bottom: 10px;
                font-weight: 500;
                font-size: 18px;
                color: #000000;
                line-height: 34px;
                text-align: center;
                .article_item_content_text {
                    text-align: left;
                }
                img {
                    max-width: 100%;
                }
            }
        }
    }
    .f-1 {
        flex: 1;
    }
    .mb-20 {
        margin-bottom: 20px;
    }
    .article_item_content_imglist_wrap {
        width: 930px;
        justify-content: space-between;
        img {
            display: block;
        }
    }
    .c-p {
        cursor: pointer;
    }
}
@media screen and (max-width: 1279px) {
    .public_box {
        padding: 0 .9375rem 3rem;
    }
    .public_menu {
        display: flex;
        align-items: center;
        font-size: .75rem;
        line-height: 3.625rem;
        border-bottom: 1px solid #c2c0be;
        img {
            height: .75rem;
            margin-right: .625rem;
        }
    }
    .public_header_title {
        position: absolute;
        bottom: 2rem;
        z-index: 9;
        color: #Fff;
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: -0.0625rem;
    }
    .public_header {
        height: 26rem;
        // padding: 0 .9375rem 2rem;
        position: relative;
        background: #000;
        &::after {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
            background-image: linear-gradient(to top, hsl(0, 0%, 0%) 0%, hsla(0, 0%, 0%, 0.9477587464) 6.36%, hsla(0, 0%, 0%, 0.8863556851) 12.99%, hsla(0, 0%, 0%, 0.8173432945) 19.85%, hsla(0, 0%, 0%, 0.7422740525) 26.91%, hsla(0, 0%, 0%, 0.6627004373) 34.12%, hsla(0, 0%, 0%, 0.5801749271) 41.46%, hsla(0, 0%, 0%, 0.49625) 48.88%, hsla(0, 0%, 0%, 0.4124781341) 56.34%, hsla(0, 0%, 0%, 0.3304118076) 63.81%, hsla(0, 0%, 0%, 0.2516034985) 71.25%, hsla(0, 0%, 0%, 0.1776056851) 78.63%, hsla(0, 0%, 0%, 0.1099708455) 85.91%, hsla(0, 0%, 0%, 0.0502514577) 93.04%, hsla(0, 0%, 0%, 0) 100%);
        }
        img {
            width: 100%;
            height: 26rem;
            object-fit: cover;
        }
    }
    .article_wrap {
        .article_item_box {
            .article_item_title {
                font-weight: bold;
                font-size: 1.5rem;
                margin-bottom: 1.25rem;
                padding-top: 2.5rem;
            }
            .article_item_content {
                .article_item_content_text {
                    margin-bottom: 1.25rem;
                    line-height: 1.5rem;
                }
                img {
                    width: 100%;
                    margin-bottom: 1.25rem;
                }
            }
        }
    }
    .about_FCCC_content {
        img {
            width: 100%;
            margin-bottom: 2.5rem;
        }
    }
}