
@media screen and (min-width: 1280px) {
    .partner_header {
        height: 192px;
        background: #000;
        .partner_header_title {
            width: 1110px;
            margin: 0 auto;
            font-weight: 800;
            font-size: 40px;
            color: #FFFFFF;
            line-height: 56px;
            padding-top: 64px;
        }
    }
    .partner_box {
        width: 1110px;
        margin: 0 auto 50px;
    }
    .partner_model2 {
        padding: 80px 0;
        ul {
            display: flex;
            justify-content: space-between;
            text-align: center;
            li {
                width: 208px;
                min-height: 274px;
                background: #CE232A;
                padding: 12px;
                .partner_model2_item1 {
                    font-weight: 800;
                    font-size: 24px;
                    color: #FFFFFF;
                    line-height: 34px;
                    padding-top: 52px;
                    margin-bottom: 36px;
                }
                .partner_model2_item2 {
                    font-weight: 800;
                    font-size: 24px;
                    color: #FFFFFF;
                    line-height: 30px;
                    margin-bottom: 20px;
                }
                .partner_model2_item3 {
                    font-weight: normal;
                    font-size: 12px;
                    color: #FFFFFF;
                    line-height: 19px;
                }
            }
        }
    }
    .partner_box_model {
        min-height: 730px;
        background: #fff;
        margin-bottom: 50px;
        padding: 0 32px;
        .partner_box_model_title {
            font-weight: 800;
            font-size: 40px;
            color: #000000;
            line-height: 56px;
            text-align: center;
            padding-top: 43px;
            margin-bottom: 11px;
        }
    }
    .partner_box_model_content {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        &.partner_box_model_content_negative {
            margin-top: -80px;
        }
        li {
            margin-right: 60px;
            &:last-child {
                margin-right: 0;
            }
            &.partner_box_model_content_remove {
                // padding-top: 64px;
            }
        }
    }
}
@media screen and (max-width: 1279px) {
    .partner_header {
        height: 12rem;
        background: #000;
        position: relative;
        .partner_header_title {
            width: 100%;
            text-align: center;
            font-weight: bold;
            font-size: 1.5rem;
            color: #FFFFFF;
            bottom: 2rem;
            position: absolute;
        }
    }
    .contact_head_menu {
        display: flex;
        align-items: center;
        font-size: .75rem;
        line-height: 3.625rem;
        border-bottom: 1px solid #c2c0be;
        img {
            height: .75rem;
            margin-right: .625rem;
        }
    }
    .partner_box {
        padding: 0 .9375rem 2.5rem;
        background: #f2f0ec;
    }
    .partner_box_model {
        min-height: 21.875rem;
        background: #fff;
        padding: 0 32px;
        .partner_box_model_title {
            font-weight: bold;
            font-size: 1.5rem;
            color: #000000;
            line-height: 3.5rem;
            margin-bottom: 1.25rem;
        }
    }
    .partner_box_model_content {
        margin: -5.25rem auto 0;
        display: flex;
        justify-content: center;
        transform: scale(0.35);
        // background: #fff;
        &.partner_box_model_content_negative {
            margin-top: -170px;
        }
        li {
            margin-right: 60px;
            &:last-child {
                margin-right: 0;
            }
            &.partner_box_model_content_remove {
                // padding-top: 64px;
            }
        }
    }
    .partner_model2 {
        padding-top: 2.5rem;
        ul {
            display: flex;
            flex-wrap: wrap;
            margin-right: -0.9375rem;
            li {
                width: calc(50% - 2.8125rem);
                min-height: 15.625rem;
                margin-right: 15px;
                background: #CE232A;
                margin-bottom: 1.25rem;
                color: #fff;
                text-align: center;
                padding: .9375rem;
                .partner_model2_item1 {
                    font-size: 1.5rem;
                    padding-top: 1.25rem;
                    font-weight: bold;
                    margin-bottom: .625rem;
                }
                .partner_model2_item2 {
                    font-size: 1.5rem;
                    font-weight: bold;
                    margin-bottom: .625rem;
                }
            }
        }
    }
}