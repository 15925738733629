@media screen and (min-width: 1280px) {
    
.registro_wrap {
    width: 100%;
    background: #fff;
    padding: 72px 0;
}
.registro_form_table {
    width: 500px;
    margin: 0 auto;
    text-align: center;
    img.registration_logo {
        width: 200px;
        height: 200px;
    }
}
.registro_lang {
    display: flex;
    margin-bottom: 24px;
    justify-content: center;
    li {
        margin: 0 10px;
        opacity: .5;
        cursor: pointer;
        &.registro_lang_active {
            opacity: 1;
        }
    }
}
.registro_title {
    padding-top: 12px;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    line-height: 36px;
    margin-bottom: 40px;
}
.registro_form {
    width: calc(100% - 42px);
    padding: 20px;
    border: 1px solid #CED4DA;
    text-align: left;
    margin-bottom: 40px;
    .registro_form_title {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #000000;
        line-height: 28px;
        margin-bottom: 20px;
    }
}
.mb-16 {
    margin-bottom: 16px;
}
.registro_form_info_confirm_title {
    padding-left: 20px;
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    line-height: 28px;
    margin-bottom: 4px;
}

.registro_form_info {
    padding: 10px 10px 0;   
    border: 1px solid #CED4DA;
    color: #444;
    :where(.css-dev-only-do-not-override-11xg00t).ant-form-item {
        margin-bottom: 12px;
    }
}
.registro_info_title {
    font-weight: bold;
}
.t-a-c {
    text-align: center;
}
.p-b-0 {
    padding-bottom: 0px;
}
.registro_form_submit {
    display: flex;
    justify-content: center;
    .ant-btn-default {
        border-color: #868e96;
        font-weight: bold;
        span {
            color: #868e96 !important;
        }
        &:hover {
            border-color: #868e96 !important;
            background: #868e96 !important;
            span {
                color: #fff !important;
            }
        }
    }
}
.registro_info_split {
    height: 1px;
    background: #CED4DA;
    margin: 10px 0;
}

:where(.css-dev-only-do-not-override-11xg00t).ant-modal .ant-modal-content {
    padding: 0;
}
:where(.css-dev-only-do-not-override-11xg00t).ant-modal .ant-modal-header {
    padding: 20px;
    height: 78px;
    margin-bottom: 1px solid #868e96;
}
.model_content_box {
    padding-bottom: 30px;
    .model_content_item_box {
        padding: 0 22px;
        border-top: 1px solid #868e96;
        
    .modal_title {
        font-weight: bold;
        font-size: 20px;
        color: #000000;
        line-height: 55px;
        text-align: center;
    }
        li {
            margin-bottom: 20px;
            line-height: 22px;
        }
        .model_content_title {
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 18px;
            color: #000000;
        }
    }
}
.a-i-c {
    align-items: center;
}
.require_btn_accept {
    margin: 0 auto;
}
.j-c-s-e {
    justify-content: space-evenly;
}
.immediately_registration {
    
    padding: 40px;
    :where(.css-dev-only-do-not-override-11xg00t).ant-btn {
        width: 250px;
    }
    :where(.css-dev-only-do-not-override-11xg00t).ant-btn-primary.ant-btn-dangerous {
        background: #CE232A;
    }
}
.modal_view_item {
    padding: 30px 22px;
    border-top: 1px solid #868e96;
    .modal_view_item_title {
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
        margin-bottom: 20px;
        color: #000;
    }
    
    .modal_view_item_content {
        display: flex;
        flex-wrap: wrap;
        li {
            width: calc(100% / 4);
            line-height: 44px;
            font-size: 14px;
            color: #706f6f;
            &.one_half_width {
                width: calc(100% / 2);
            }
            span {
                color: #706f6f;
            }
            &.width_all {
                width: 100%;
            }
        }
    }
}
:where(.css-dev-only-do-not-override-11xg00t) a{
    display: none;
}
}
@media screen and (max-width: 1279px) {
    .registro_wrap {
        width: 100%;
        background: #fff;
        padding: 72px 0;
    }
    .registro_form_table {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        img.registration_logo {
            width: 200px;
            height: 200px;
        }
    }
    .registro_lang {
        display: flex;
        margin-bottom: 24px;
        justify-content: center;
        li {
            margin: 0 10px;
            opacity: .5;
            cursor: pointer;
            &.registro_lang_active {
                opacity: 1;
            }
        }
    }
    .registro_title {
        padding-top: 12px;
        font-weight: bold;
        font-size: 20px;
        color: #000000;
        line-height: 36px;
        margin-bottom: 40px;
    }
    .registro_form {
        width: calc(100% - 42px);
        padding: 20px;
        border-bottom: 1px solid #CED4DA;
        text-align: left;
        margin-bottom: 40px;
        .registro_form_title {
            text-align: center;
            font-weight: bold;
            font-size: 18px;
            color: #000000;
            line-height: 28px;
            margin-bottom: 20px;
        }
    }
    .mb-16 {
        margin-bottom: 16px;
    }
    .registro_form_info_confirm_title {
        padding-left: 20px;
        text-align: left;
        font-weight: bold;
        font-size: 18px;
        color: #000000;
        line-height: 28px;
        margin-bottom: 4px;
    }
    
    .registro_form_info {
        padding: 10px 10px 0;   
        border: 1px solid #CED4DA;
        color: #444;
        :where(.css-dev-only-do-not-override-11xg00t).ant-form-item {
            margin-bottom: 12px;
        }
    }
    .registro_info_title {
        font-weight: bold;
    }
    .t-a-c {
        text-align: center;
    }
    .p-b-0 {
        padding-bottom: 0px;
    }
    .registro_form_submit {
        display: flex;
        justify-content: center;
        .ant-btn-default {
            width: 80% !important;
            border-color: #868e96;
            font-weight: bold;
            span {
                color: #868e96 !important;
            }
            &:hover {
                border-color: #868e96 !important;
                background: #868e96 !important;
                span {
                    color: #fff !important;
                }
            }
        }
    }
    .registro_info_split {
        height: 1px;
        background: #CED4DA;
        margin: 10px 0;
    }
    
    :where(.css-dev-only-do-not-override-11xg00t).ant-modal .ant-modal-content {
        padding: 0;
    }
    :where(.css-dev-only-do-not-override-11xg00t).ant-modal .ant-modal-header {
        padding: 20px;
        height: 78px;
        margin-bottom: 1px solid #868e96;
    }
    .model_content_box {
        padding-bottom: 30px;
        .model_content_item_box {
            padding: 0 22px;
            border-top: 1px solid #868e96;
            
        .modal_title {
            font-weight: bold;
            font-size: 20px;
            color: #000000;
            line-height: 55px;
            text-align: center;
        }
            li {
                margin-bottom: 20px;
                line-height: 22px;
            }
            .model_content_title {
                margin-bottom: 10px;
                font-weight: bold;
                font-size: 18px;
                color: #000000;
            }
        }
    }
    .a-i-c {
        align-items: center;
    }
    .require_btn_accept {
        margin: 0 auto;
        display: block;
    }
    .j-c-s-e {
        justify-content: space-evenly;
    }
    .immediately_registration {
        
        padding: 40px 0;
        display: flex;
        justify-content: space-around;
        :where(.css-dev-only-do-not-override-11xg00t).ant-btn {
            width: 150px;
        }
        :where(.css-dev-only-do-not-override-11xg00t).ant-btn-primary.ant-btn-dangerous {
            background: #CE232A;
        }
    }
    .modal_view_item {
        padding: 30px 22px;
        border-top: 1px solid #868e96;
        .modal_view_item_title {
            font-size: 16px;
            font-weight: bold;
            line-height: 30px;
            margin-bottom: 20px;
            color: #000;
        }
        
        .modal_view_item_content {
            display: flex;
            flex-wrap: wrap;
            li {
                width: 100%;
                line-height: 44px;
                font-size: 14px;
                color: #706f6f;
                span {
                    color: #706f6f;
                }
                &.width_all {
                    width: 100%;
                }
            }
        }
    }
    :where(.css-dev-only-do-not-override-11xg00t) a{
        display: none;
    }
}
input::placeholder {
    color: #999 !important;
}
.ant-select-selection-placeholder {
    color: #999 !important;
}
textarea::placeholder {
    color: #999 !important;
}