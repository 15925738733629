@media screen and (min-width: 1280px) {
.public_footer_wrap {
    width: 100%;
    background: #003366;
}
    .public_footer {
        padding: 0 15px 15px;
        max-width: 1500px;
        margin: 0 auto;
    }
    .public_footer_menu {
        border-bottom: 2px solid #D9D9D9;
        margin-bottom: 32px;
        ul {
            padding: 44px 0 54px;
            display: flex;
            li {
                width: 183px;
                line-height: 32px;
                font-weight: bold;
                font-size: 14px;
                color: #FFFFFF;
                margin-right: 15px;
                cursor: pointer;
            }
        }
    }
    .public_footer_content {
        display: flex;
        margin-bottom: 80px;
        .public_footer_content_item {
            width: calc(100% / 3);
            min-width: 350px;
            .public_footer_content_item_title {
                line-height: 32px;
                font-weight: 800;
                font-size: 20px;
                color: #FFFFFF;
                margin-bottom: 8px;
            }
            ul {
                li {
                    font-weight: bold;
                    font-size: 14px;
                    color: #748FAB;
                    line-height: 32px;
                    margin-bottom: 10px;
                    cursor: pointer;
                }
            }
        }
    }
    .public_footer_bottom {
        width: 100%;
        background: #DB0812;
        height: 50px;
        text-align: center;font-weight: 800;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 50px;
        font-weight: normal;
    }
    .public_footer_logo {
        display: flex;
        justify-content: space-between;
        .public_footer_logo_item {
            width: calc(100% / 3);
            min-width: 350px;
            &:first-child {
                img {
                    margin-bottom: 50px;
                }
            }
            .public_footer_platform {
               display: flex;
                li {
                    margin-right: 10px;
                    user-select: none;
                    cursor: pointer;
                }
            }
        }
        ul.public_footer_logo_item {
            align-items: center;
            height: 80px;
            li {
                margin-right: 15px;
            }
        }
    }
    .public_footer_logo_item_last {
        width: calc(100% / 3);
        .public_footer_logo_item_last_title {
                font-weight: 800;
                font-size: 24px;
                color: #FFFFFF;
                line-height: 32px;
                margin-bottom: 14px;
        }  
        ul {
            &.public_footer_item_last_left {
                float: left;
                margin-right: 30px;
                li:first-child {
                    line-height: 40px;
                    margin-bottom: 22px;
                }
            }
            &.public_footer_item_last_right {
                float: left;
            }
            li {
                font-weight: bold;
                font-size: 14px;
                color: #748FAB;
                line-height: 20px;
            }
        }
    }
}
@media screen and (max-width: 1279px) {
    .public_footer_wrap {
        background: #003366;
        padding: 2.5rem .9375rem 6.25rem;
        color: #fff;
        position: relative;
    }
    .public_footer {
        width: 100%;
    }
    .public_footer_menu {
        width: 100%;
        ul {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 2.5rem;
            border-bottom: .125rem solid #fff;
            margin-bottom: 2.5rem;
            li {
                width: 50%;
                line-height: 2rem;
                font-weight: bold;
            }
        }
    }
    .public_footer_content {
        display: flex;
        flex-wrap: wrap;
        .public_footer_content_item {
            width: 100%;
            .public_footer_content_item_title {
                font-size: 1.25rem;
                font-weight: bold;
                line-height: 2rem;
            }
            ul {
                li {
                    line-height: 2rem;
                    font-size: .875rem;
                    color: #748FAB; 
                }
                margin-bottom: 2.5rem;
            }
            .public_footer_content_menu_wrap {
                width: calc(100% - .9375rem);
            }
        }
    }
    
    .public_footer_logo {
        text-align: center;
    }
    .public_footer_logo_item_platform {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.625rem;
    }
    .public_footer_logo_item {

        .footer_big_logo {
            margin-bottom: 4.375rem;
        }
    }
    .public_footer_logo_item_icon {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 3.625rem;
    }
    .public_footer_bottom {
        background: #CE232A;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: .9375rem;
        text-align: center;
        font-size: .75rem;
    }
    .public_footer_logo_item_last {
        font-size: .75rem;
        .public_footer_logo_item_last_title {
            font-size: 1.25rem;
            font-weight: bold;
            margin-bottom: .9375rem;
        }
        .public_footer_item_last_left {
            li {
                margin-bottom: .9375rem;
            }
        }
    }
}
