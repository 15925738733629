@media screen and (min-width: 1280px) {
    
.public_header_wrap {
    width: 100%;
    height: 156px;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 999;
}
.header_logo {
    cursor: pointer;
}
.wrap_head_top {
    height: 116px;
    display: flex;
    align-items: center;
    padding: 0 20px 0 15px;
    justify-content: space-between;
}
.header_menu {
    width: calc(100% - 500px);
    min-width: 770px;
}
.header_menu_top {
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    justify-content: space-between;
}
ul.header_menu_top_list {
    line-height: 44px;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    li {
        margin-right: 32px;
        user-select: none;
        cursor: pointer;
        
        &.header_menu_top_active {
            color: #CE232A;
        }
    }
}
ul.header_menu_top_platform {
    display: flex;
    li {
        margin-left: 10px;
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }
}
.header_menu_bottom {
    display: flex;
    justify-content: space-between;
}
.header_menu_bottom_menu {
    line-height: 70px;
    display: flex;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    li {
        margin-right: 32px;
        cursor: pointer;
        user-select: none;
        &.header_menu_bottom_menu_fisrt_menu {
            display: flex;
            align-items: center;
            position: relative;
            span.header_menu_bottom_menu_fisrt_menu_icon {
                transform: translateY(7px);
                margin-left: 10px;
                display: block;
                border: 5px solid #272636;
                border-color: #272636 transparent transparent transparent;
            }
            &:hover {
                .header_menu_bottom_menu_second {
                    display: block;
                }
            }
            &.header_menu_bottom_menu_fisrt_menu_active::after {
                position: absolute;
                content: '';
                width: 100%;
                height: 3px;
                bottom: 0.8rem;
                background: #CE232A;
            }
        }
    }
}
.header_menu_bottom_lang {
    display: flex;
    align-items: center;
    li {
        margin-left: 16px;
        cursor: pointer;
        user-select: none;
        border-radius: 50%;
        border: 2px solid #999;
        img {
            display: block;
            opacity: .6;
        }
        &:hover {
            border: 2px solid #CE232A;
            img {
                opacity: 1;
            }
        }
        &.menu_active {
            border: 2px solid #CE232A;
            img {
                opacity: 1;
            }
            &:hover {
                opacity: 0.8;
            }
        }
    }
}
.head_banner_box {
    height: 40px;
    background: #CE232A;
    line-height: 40px;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    overflow: hidden;
    position: relative;
    z-index: 999;
    ul {
        transition: all 0.5s ease;
        animation: slide 20s linear infinite;
        &:hover {
            animation-play-state: paused;
        }
        li {
            margin-right: 16px;
            display: flex;
            align-items: center;
            line-height: 40px;
            white-space: nowrap;
            flex-wrap: nowrap;
            font-size: 14px;
            cursor: pointer;
            span {
                display: block;
                width: 0;
                height: 0;
                margin-left: 8px;
                border: 7px solid #272636;
                border-color: transparent transparent transparent #272636;;
            }
        }
    }
}
    .content_wrap {
        width: 100%;
        }
        .content__box {
            width: 1280px;
            margin: 0 auto;
            padding: 80px 0 80px;
        }
        .content_text {
            text-align: center;
            width: 730px;
            margin: 0 auto;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
            line-height: 26px;
        }
        .header_menu_bottom_menu_second {
            position: absolute;
            padding: 20px 10px;
            background: #fff;
            top: 70px;
            left: 0;
            z-index: 9999;
            display: none;
            transition: 1s ease-in-out;
            width: 500px;
            li {
                height: 36px;
                white-space: nowrap;
                font-weight: normal;
                font-size: 16px;
                display: flex;
                justify-content: space-between;
                line-height: 36px;
                border-bottom: 1px solid #c2c0be;
                margin: 0;
                padding: 0;
                span {
                    color: #CE232A;
                    margin-left: 50px;
                    font-weight: bolder;
                }
            }
        }
}
@media screen and (max-width: 1279px) {
    .public_header_wrap {
        width: 100%;
        // overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        background: #F2F0EC;
        z-index: 999;
    }
    .wrap_head_top {
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .header_logo {
            // padding-left: .9375rem;
            // background-image: url('../img/head/1000miglia-sprite.png');
            // background-repeat: no-repeat;
            // background-size: 256px 172px;
            // transform: scale(0.7);
            // &::before {
            //     display: block;
            //     width: 114px;
            //     height: 76px;
            //     content: "";
            //     background-position: 0 0;
            // }
            img {
                width: 13.75rem;
                padding-left: .625rem;
            }
        }
        .head_menu_btn {
            display: flex;
            align-items: center;
            .head_menu_btn_icon_box {
                width: 3.125rem;
                height: 3.125rem;
                display: flex;
                align-items: center;
                margin-left: 0 1.4375rem 0 1.375rem;
                justify-content: center;
            }
            // img {
            //     margin: 0 1.4375rem 0 1.375rem;
            // }
        }
    }
    .head_banner_box {
        position: absolute;
        height: 3.125rem;
        color: #fff;
        width: 100%;
        line-height: 3.125rem;
        background: #CE232A;
        padding: 0 20px;
        overflow: hidden;
        ul {
            display: flex;
            z-index: 999;
            // overflow: hidden;
            white-space: nowrap;
            transition: all 0.5s ease;
            animation: slide 10s linear infinite;
            li {
                margin-right: 1.25rem;
                display: flex;
                align-items: center;
                span {
                    display: block;
                    width: 0;
                    height: 0;
                    margin-left: 8px;
                    border: 7px solid #272636;
                    border-color: transparent transparent transparent #272636;;
                }
            }
        }
    }
    .header_menu {
        position: absolute;
        height: calc(100vh - 4rem);
        background: #F2F0EC;
        z-index: 99999;
        top: 4rem;
        width: calc(100% - 1.875rem);
        left: 0px;
        transition: .5s ease-in-out;
        padding: .9375rem;
        overflow: auto;
    }
    .header_menu_bottom_lang {
        display: flex;
        padding-bottom: .9375rem;
        margin-bottom: .9375rem;
        border-bottom: .0625rem solid #D9D9D9;;
        li {
            margin-right: .625rem;
        }
    }
    .header_menu_top_platform {
        display: flex;
        li {
            margin-right: .625rem;
        }
    }
    .header_menu_bottom {
        margin-bottom: 1.875rem;
    }
    .header_menu_bottom_menu_fisrt_menu {
        line-height: 3.125rem;
        border-bottom: 1px solid #c2c0be;
        font-weight: 800;
        font-size: 1.5rem;
        color: #000000;
        position: relative;
        .down_icon {
            position: absolute;
            right: 1rem;
            top: 1.5rem;
        }
        .header_menu_bottom_menu_second {
            background: #fff;
            line-height: 2.125rem;
            padding: .9375rem;
            border-top: 1px solid #c2c0be;
            height: auto;
            display: none;
            transition: 1s ease-in-out;
            &.header_menu_bottom_menu_second_active
            {
                display: block;
            }
            li {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #c2c0be;
                font-weight: 400;
                font-size: .875rem;
                color: #000000;
                span {
                    color: #CE232A;
                }
            }
        }
        &.header_menu_top {
            font-size: .875rem;
            font-weight: normal;
        }
    }
}
@keyframes slide {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

