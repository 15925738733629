
ul, li {
    list-style: none;
}
*{
    margin: 0;
    padding: 0;
}
@media screen and (min-width: 1280px) {
    .sf-content-wrap {
        min-width: 1280px;
        overflow-x: scroll;
    }
    .sf_wrapper {
        width: 100%;
        height: auto;
        background: #F2F0EC;
    }
    .d-f {
        display: flex;
    }
    .sf-content-wrap {
        // margin-top: -40px;
        padding-top: 120px;
    }
    .bg-eae7e1 {
        background: #EAE7E1;
    }
    .bg-fff {
        background: #fff;
    }
    .contact_wrap {
        .contact_header_box_bg {
            background: #000;
            height: 192px;
            p {
                width: 1110px;
                margin: 0 auto;
                font-weight: 800;
                font-size: 40px;
                color: #FFFFFF;
                line-height: 56px;
                padding-top: 64px;
            }
        }
        .contact_content {
            width: 1110px;
            margin: 0 auto;
            .contact_content_text {
                width: calc(100% - 90px);
                background: #fff;
                margin-bottom: 86px;
                padding: 105px 45px;
                ul {
                    display: flex;
                    justify-content: space-between;
                    text-align: center;
                    li {
                        img {
                            margin-bottom: 22px;
                        }
                        .contact_content_text_item_title {
                            font-weight: 800;
                            font-size: 24px;
                            color: #000000;
                            line-height: 30px;
                            margin-bottom: 22px;
                        }
                        .contact_content_text_item_email {
                            font-weight: 400;
                            font-size: 16px;
                            color: #000000;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }

    .contact_head_menu {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        line-height: 60px;
        font-weight: 500;
        font-size: 12px;
        color: #000000;
        img {
            margin-right: 37px;
            cursor: pointer;
        }
    }

    :where(.css-dev-only-do-not-override-11xg00t).ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background: #5897FB;
        color: #fff;
    }
    :where(.css-dev-only-do-not-override-11xg00t).ant-select-dropdown .ant-select-item-option:hover {
        background: #DDDDDD;
    }
    :where(.css-dev-only-do-not-override-11xg00t).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
        // background: url('../img/registro/radio_check.png') no-repeat center / cover;
        background-color: transparent;
        border-color: #000;
        // width: 20px;
        // height: 20px;
    }
    :where(.css-dev-only-do-not-override-11xg00t).ant-radio-wrapper .ant-radio-inner {
        // background: url('../img/registro/radio.png') no-repeat center / cover;
        // border-color: transparent;
        border-color: #000;
        // width: 20px;
        // height: 20px;
    }
    :where(.css-dev-only-do-not-override-11xg00t).ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
        background: #000;
        // content: '';
        // font-weight: bolder;
        // left: 9px;
        // top: 5px;
    }
    :where(.css-dev-only-do-not-override-11xg00t).ant-radio-wrapper .ant-radio:hover {
        // border-color: transparent;
    }
    :where(.css-dev-only-do-not-override-11xg00t).ant-radio-wrapper {
        font-size: 12px;
    }

    .content_title {
        font-weight: 500;
        font-size: 40px;
        text-align: center;
        color: #000000;
        line-height: 56px;
        margin-bottom: 32px;
    }
    .p-t-b-80 {
        padding: 80px 0;
    }
    :where(.css-dev-only-do-not-override-11xg00t).ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        display: none;
    }
    .press_header {
        height: 400px;
        width: 100%;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
        }
        p {
            width: 1110px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: 800;
            font-size: 40px;
            color: #FFFFFF;
            line-height: 47px;
            z-index: 999;
        }
    &:before {
            display: block;
            content: "";
            position: absolute;
            z-index: 3;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            opacity: 0.4;
            background-color: #000;
        }
    }
    .press_box {
        width: 1110px;
        margin: 0 auto 50px;
    }
    .press_title {
        font-weight: 800;
        font-size: 40px;
        color: #000000;
        line-height: 47px;
        padding: 80px 0 40px;
        text-align: center;
    }
    .press_introduce {
        width: 920px;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        line-height: 26px;
        text-align: center;
        margin: 0 auto;
    }
    .press_title1 {
        padding-top: 80px;
        font-weight: 800;
        font-size: 24px;
        color: #000000;
        line-height: 34px;
        margin-bottom: 20px;
    }
    .press_contact_model {
        text-align: center;
        padding: 80px;
        background: #fff;
    }
    .press_contact_model_title {
        font-weight: 800;
        font-size: 16px;
        color: #000000;
        line-height: 22px;
        margin-bottom: 40px;
    }
    .press_contact_model_methods {
        display: flex;
        justify-content: space-between;
        // display: inline-block;
        margin-bottom: 44px;
        li {
            width: 25%;
            img {
                margin-bottom: 22px;
            }
            div {
                font-weight: 800;
                font-size: 24px;
                color: #000000;
                line-height: 30px;
            }
        }
    }
    .press_contact_model_content {
        font-weight: 800;
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    }
    .mb-60 {
        margin-bottom: 60px;
    }
    .press_contact_methods {
        // text-align: center;

        width: fit-content;
        margin: 0 auto 40px;
        li {
            font-weight: 400;
            font-size: 16px;
            color: #000000;
            line-height: 26px;
        }
    }
    .b-b-1-D9D9D9 {
        border-bottom: 1px solid #D9D9D9;
    }
}
@media screen and (max-width: 1279px) {
    .sf_wrapper {
        width: 100%;
        overflow: hidden;
    }
    .sf-content-wrap {
        padding-top: 4rem;
        position: relative;
        z-index: 9;
    }
    .press_header {
        height: 400px;
        width: 100%;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
        }
        p {
            width: 100%;
            position: absolute;
            text-align: center;
            bottom: 2rem;
            font-weight: 800;
            font-size: 1.5rem;
            color: #FFFFFF;
            line-height: 47px;
            z-index: 999;
        }
        &:before {
            display: block;
            content: "";
            position: absolute;
            z-index: 3;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            opacity: 0.4;
            background-color: #000;
        }
    }
    .press_box {
        padding: 0 .9375rem;
        background: #f2f0ec;
        .press_title {
            font-size: 1.5rem;
            letter-spacing: -0.0625rem;
            padding-top: 1.25rem;
            margin-bottom: 1.25rem;
            text-align: center;
            font-weight: bold;
        }
        .press_introduce {
            text-align: center;
            line-height: 1.5rem;
            margin-bottom: 1.25rem;
        }
        .press_title1 {
            font-size: 1.25rem;
            margin-bottom: 1.25rem;
            font-weight: bold;
        }
        .press_contact_model {
            background: #fff;
            padding: .9375rem;
            .press_contact_model_title {
                font-size: 1.125rem;
                text-align: center;
                line-height: 1.5rem;
                margin-bottom: 1.25rem;
            }
            .press_contact_model_methods {
                display: flex;
                text-align: center;
                flex-wrap: wrap;
                li {
                    width: 50%;
                    padding: 1.25rem 0;
                    img {
                        margin-bottom: .9375rem;
                    }
                    font-weight: bold;
                    font-size: 1.25rem;
                }
            }
            .press_contact_model_content {
                margin-bottom: 1.25rem;
                text-align: center;
            }
        }
    }
    .press_contact_methods {
        text-align: center;
        background: #f2f0ec;
        line-height: 1.875rem;
        padding: 1.25rem 0;
    }
    .contact_wrap {
        .contact_header_box_bg {
            background: #000;
            height: 12rem;
            position: relative;
            p {
                width: 100%;
                text-align: center;
                font-weight: bold;
                font-size: 1.5rem;
                color: #FFFFFF;
                line-height: 56px;
                position: absolute;
                bottom: 2rem;
            }
        }
        .contact_content {
            padding: 1.25rem .9375rem;
            background: #f2f0ec;
            .contact_content_text {
                width: 100%;
                background: #fff;
                margin: 1.25rem 0;
                ul {
                    display: flex;
                    justify-content: space-between;
                    text-align: center;
                    flex-wrap: wrap;
                    li {
                        width: 50%;
                        padding: .9375rem 0;
                        img {
                            margin-bottom: 22px;
                        }
                        .contact_content_text_item_title {
                            font-weight: bold;
                            font-size: 1.25rem;
                            color: #000000;
                            line-height: 1.875rem;
                            margin-bottom: 1.375rem;
                        }
                        .contact_content_text_item_email {
                            font-weight: 400;
                            font-size: .75rem;
                            color: #000000;
                            line-height: 1.375rem;
                        }
                    }
                }
            }
        }
    }
}
