
@media screen and (min-width: 1280px) {
  .homepage_box {
    margin-top: -120px;
  }  
  .homepage_box_video_box {
    
    background: #003366;
  }
.homepage_box_video {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: block;
    object-fit: fill;
    min-height: 50vw;
    min-width: 170vh;
}
.content_wrap {
    .content__box {
        text-align: center;
        
    }
}
.more_info {
    width: fit-content;
    font-weight: bold;
    font-size: 14px;
    color: #FF1111;
    line-height: 16px;
    text-decoration: underline;
    cursor: pointer;
    margin: 0 auto;
}
div.content_text {
    margin-bottom: 24px;
}

.content_title {
    &.homepage_box_model1_title {
        margin-bottom: 12px;
    }
}

.content_lunbo_wrap {
    width: 100%;
    overflow: hidden;
    position: relative;
    .content_lunbo_box {
        position: relative;
        height: 458px;
        transition: 1s ease-in-out;
        li {
            float: left;
            .content_lunbo_item_insideBox {
                width: 1110px;
                height: 350px;
                margin: 0 auto;
                padding-top: 12px;
                background-color: #CE232A;
                position: relative;
                img {
                    display: block;
                    position: absolute;
                    top: 12px;
                    left: 0;
                    width: 100%;
                    height: 350px;
                    -o-object-fit: cover;
                    object-fit: cover;
                    -o-object-position: 50% 60%;
                    object-position: 120% 60%;
                    overflow-clip-margin: content-box;
                    overflow: clip;
                }
                &::after {
                    display: block;
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0.8;
                    background-image: linear-gradient(to top, hsl(0, 0%, 0%) 0%, hsla(0, 0%, 0%, 0.9477587464) 6.36%, hsla(0, 0%, 0%, 0.8863556851) 12.99%, hsla(0, 0%, 0%, 0.8173432945) 19.85%, hsla(0, 0%, 0%, 0.7422740525) 26.91%, hsla(0, 0%, 0%, 0.6627004373) 34.12%, hsla(0, 0%, 0%, 0.5801749271) 41.46%, hsla(0, 0%, 0%, 0.49625) 48.88%, hsla(0, 0%, 0%, 0.4124781341) 56.34%, hsla(0, 0%, 0%, 0.3304118076) 63.81%, hsla(0, 0%, 0%, 0.2516034985) 71.25%, hsla(0, 0%, 0%, 0.1776056851) 78.63%, hsla(0, 0%, 0%, 0.1099708455) 85.91%, hsla(0, 0%, 0%, 0.0502514577) 93.04%, hsla(0, 0%, 0%, 0) 100%);
                }
                .content_lunbo_item_title {
                    width: 400px;
                    height: 40px;
                    background: #DB0812;
                    position: absolute;
                    bottom: -20px;
                    left: 50%;
                    transform: translateX(-50%);
                    text-align: center;
                    line-height: 40px;
                    font-weight: bold;
                    font-size: 14px;
                    color: #FFFFFF;
                    cursor: pointer;
                    z-index: 99;
                }
                .content_lunbo_item_text {
                    width: calc(100% - 50px);
                    display: flex;
                    justify-content: space-between;
                    position: absolute;
                    color: #fff;
                    bottom: 40px;
                    z-index: 999;
                    font-size: 34px;
                    text-transform: uppercase;
                    padding-right: 30px;
                    line-height: 1;
                    font-weight: 200;
                    letter-spacing: 0;
                    padding-left: 20px;
                    letter-spacing: -1px;
                }
            }
            
        }
    }
    .content_lunbo_operation {
        position: absolute;
        bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        .content_lunbo_circle_btn_wrap {
            display: flex;
            li {
                width: 10px;
                height: 10px;
                background: #FFFFFF;
                border-radius: 50%;
                margin: 6px;
                cursor: pointer;
                &.content_lunbo_circle_btn_item_active {
                    background: #FF1111;
                }
            }
        }
        .content_lunbo_circle_btn_leftAndRight {
            margin-left: 290px;
            position: relative;
            :where(.css-dev-only-do-not-override-11xg00t).ant-btn.ant-btn-icon-only {
                background: transparent;
                // border-color: #000;
                color: #000;
                &:hover {
                    color: #979593;
                    border-color: #979593;
                }
            }
            :where(.css-dev-only-do-not-override-11xg00t).ant-btn.ant-btn-circle.ant-btn-lg {
                background: transparent;
                border-color: #000;
                color: #000;
                &:hover {
                    color: #979593;
                    border-color: #979593;
                }
            }
        }
    }
}
.content__box .m-b-44 {
    margin-bottom: 44px;
}
.action_model {
    width: 1110px;
    margin: 0 auto;
}
.action_model_1 {
    margin-bottom: 30px;
    display: flex;
    .action_model_1_l {
        width: 462px;
        padding-top: 10px;
        height: 380px;
        background: #CE232A;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .action_model_1_r {
        flex: 1;
        background: #003366;
        .action_model_1_r_1 {
            font-weight: bold;
            font-size: 16px;
            color: #FF1111;
            line-height: 22px;
            padding-top: 42px;
            margin-bottom: 4px;
        }
        .action_model_1_r_2 {
            font-weight: bold;
            font-size: 28px;
            color: #FFFFFF;
            line-height: 39px;
        }
        .action_model_1_r_3 {
            padding-top: 16px;
            font-weight: bold;
            font-size: 32px;
            color: #FFFFFF;
            margin-bottom: 40px;
        }
        .action_model_1_r_4 {
            font-weight: bold;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 22px;
            margin-bottom: 40px;
        }
        .action_model_1_r_5 {
            width: 360px;
            height: 40px;
            outline: none;
            border: none;
            cursor: pointer;
            background: #DB0812;
            font-weight: bold;
            font-size: 14px;
            color: #FFFFFF;
        }
    }
}
.action_model_2 {
    display: flex;
    justify-content: space-between;
    .action_model_2_1 {
        width: calc((100% - 30px) / 2);
        height: 410px;
        background: #0F9F15;
        padding: 12px 0 3px;
        position: relative;
        img {
            width: 100%;
            height: 370px;
            object-fit: cover;
        }
        &::after {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100% - 43px);
            opacity: 0.5;
            background-image: linear-gradient(to top, hsl(0, 0%, 0%) 0%, hsla(0, 0%, 0%, 0.9477587464) 6.36%, hsla(0, 0%, 0%, 0.8863556851) 12.99%, hsla(0, 0%, 0%, 0.8173432945) 19.85%, hsla(0, 0%, 0%, 0.7422740525) 26.91%, hsla(0, 0%, 0%, 0.6627004373) 34.12%, hsla(0, 0%, 0%, 0.5801749271) 41.46%, hsla(0, 0%, 0%, 0.49625) 48.88%, hsla(0, 0%, 0%, 0.4124781341) 56.34%, hsla(0, 0%, 0%, 0.3304118076) 63.81%, hsla(0, 0%, 0%, 0.2516034985) 71.25%, hsla(0, 0%, 0%, 0.1776056851) 78.63%, hsla(0, 0%, 0%, 0.1099708455) 85.91%, hsla(0, 0%, 0%, 0.0502514577) 93.04%, hsla(0, 0%, 0%, 0) 100%);
        }
        a {
            display: block;
            width: 100%;
            height: 40px;
            background: #fff;
            font-weight: bold;
            font-size: 16px;
            color: #FF1111;
            position: absolute;
            bottom: 3px;
            left: 0;
            line-height: 40px;
            cursor: pointer;
        }
    }
    
    .action_model_title {
        position: absolute;
        top: 57px;
        left: 0;
        font-weight: bold;
        font-size: 40px;
        color: #FFFFFF;
        line-height: 40px;
        text-align: center;
        width: 100%;
        z-index: 99;
    }
    .action_model_2_2 {
        width: calc((100% - 30px) / 2);
        background: #51B9E5;
        padding: 12px 0 3px;
        height: 410px;
        position: relative;
        img {
            width: 100%;
            height: 370px;
            object-fit: cover;
        }
        &::after {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100% - 43px);
            opacity: 0.5;
            background-image: linear-gradient(to top, hsl(0, 0%, 0%) 0%, hsla(0, 0%, 0%, 0.9477587464) 6.36%, hsla(0, 0%, 0%, 0.8863556851) 12.99%, hsla(0, 0%, 0%, 0.8173432945) 19.85%, hsla(0, 0%, 0%, 0.7422740525) 26.91%, hsla(0, 0%, 0%, 0.6627004373) 34.12%, hsla(0, 0%, 0%, 0.5801749271) 41.46%, hsla(0, 0%, 0%, 0.49625) 48.88%, hsla(0, 0%, 0%, 0.4124781341) 56.34%, hsla(0, 0%, 0%, 0.3304118076) 63.81%, hsla(0, 0%, 0%, 0.2516034985) 71.25%, hsla(0, 0%, 0%, 0.1776056851) 78.63%, hsla(0, 0%, 0%, 0.1099708455) 85.91%, hsla(0, 0%, 0%, 0.0502514577) 93.04%, hsla(0, 0%, 0%, 0) 100%);
        }
        a {
            display: block;
            width: 100%;
            height: 40px;
            background: #fff;
            font-weight: bold;
            font-size: 16px;
            color: #FF1111;
            position: absolute;
            bottom: 3px;
            left: 0;
            line-height: 40px;
            cursor: pointer;
        }
    }
}
.content_wrap_header {
    height: 400px;
    position: relative;
    img.content_wrap_header_bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        object-fit: cover;
    }
    img.content_wrap_header_logo {
        position: absolute;
        left: 50%;
        bottom: -38px;
        transform: translateX(-50%);
    }
}
.content_wrap .mb-60 {
    margin-bottom: 60px;
}
.classical_model_1 {
    width: 100%;
    height: 605px;
    position: relative;
    margin-bottom: 60px;
    .classical_model_1_title {
        position: absolute;
        width: 162px;
        height: 40px;
        background: #DB0812;
        left: 50%;
        top: -20px;
        transform: translateX(-50%);
        line-height: 40px;
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
    }
    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.classical_model_2 {
    display: flex;
    justify-content: space-between;
    .classical_model_2_item {
        width: calc((100% - 30px) / 2 - 80px);
        height: 310px;
        padding: 40px;
        .classical_model_2_item_content1 {
            font-weight: 800;
            font-size: 22px;
            color: #FFFFFF;
            line-height: 31px;
        }
        .classical_model_2_item_content2 {
            margin: 0 auto;
            width: 40px;
            height: 5px;
            background: #FFFFFF;
            margin-bottom: 52px;
        }
        .classical_model_2_item_content3 {
            font-weight: normal;
            font-size: 16px;
            color: #FFFFFF;
            margin-bottom: 16px;
            line-height: 22px;
        }
        button {
            width: 360px;
            height: 40px;
            outline: none;
            border: none;
            font-weight: normal;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 40px;
            cursor: pointer;
        }
        .mb-40 {
            margin-bottom: 40px;
        }
    }
    .classical_model_2_item_1 {
        background: #003366;
        button {
            background: #4C7094;
        }
    }
    .classical_model_2_item_2 {
        background: #CE232A;
        button {
            background: #DD656A;
        }
    }
}
.social_media {
    padding: 80px 85px;
    .social_media_inside_box {
        width: 1110px;
        margin: 0 auto;
    }
    .social_media_title1 {
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        line-height: 16px;
        margin-bottom: 14px;
    }
    .social_media_title2 {
        font-weight: bold;
        font-size: 48px;
        color: #DB0812;
        line-height: 56px;
        margin-bottom: 16px;
    }
}
.classical_model {
    width: 1110px;
    margin: 0 auto;
}
.social_media_menu {
    display: flex;
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 32px;
    li {
        // width: 190px;
        margin-right: 80px;
        line-height: 60px;
        cursor: pointer;
        font-weight: 500;
        font-size: 24px;
        color: #003366;
        &.social_media_menu_item_active {
            span {
                position: relative;
            }
            span::after {
                position: absolute;
                width: 100%;
                height: 3px;
                content: '';
                background: #003366;
                bottom: -15px;
                left: 0;
            }
        }
    }
}
.social_media_video_title {
    margin-bottom: 17px;
    font-size: 14px;
}
.social_media_video {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -18px;
    li {
        width: 250px;
        height: 250px;
        margin: 15px 18px;
        position: relative;
        cursor: pointer;
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
}
@media screen and (max-width: 1279px) {
    .content_lunbo_circle_btn_leftAndRight {
        position: relative;
        z-index: 999;
    }
    .homepage_box_video_box {
        min-height: 27.5rem;
        max-height: 41.25rem;
        height: calc(100vh - 4rem);
        position: relative;
        overflow: hidden;
        background: #003366;
        
    }
    .homepage_box_video_box:before {
            height: 90%;
            opacity: 0.5;
            position: absolute;
            top: 0;
            background-color: transparent;
            background-image: linear-gradient(to bottom, hsl(0, 0%, 0%) 0%, hsla(0, 0%, 0%, 0.9468148148) 5.32%, hsla(0, 0%, 0%, 0.8878518519) 11.21%, hsla(0, 0%, 0%, 0.824) 17.6%, hsla(0, 0%, 0%, 0.7561481481) 24.39%, hsla(0, 0%, 0%, 0.6851851852) 31.48%, hsla(0, 0%, 0%, 0.612) 38.8%, hsla(0, 0%, 0%, 0.5374814815) 46.25%, hsla(0, 0%, 0%, 0.4625185185) 53.75%, hsla(0, 0%, 0%, 0.388) 61.2%, hsla(0, 0%, 0%, 0.3148148148) 68.52%, hsla(0, 0%, 0%, 0.2438518519) 75.61%, hsla(0, 0%, 0%, 0.176) 82.4%, hsla(0, 0%, 0%, 0.1121481481) 88.79%, hsla(0, 0%, 0%, 0.0531851852) 94.68%, hsla(0, 0%, 0%, 0) 100%);
    }
    .homepage_box_video {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        min-width: 177.77777778vh;
        height: 100%;
        min-height: 100%;
        min-height: 56.25vw;
        transform: translate(-50%, -50%);
    }
    .content_wrap {
        text-align: center;
        padding: 2.5rem 0;
        .content_title  {
            font-size: 1.25rem;
            font-weight: bold;
            margin-bottom: 1.875rem;
        }
        .homepage_box_model1_title {
            margin-bottom: .625rem;
        }
        .content_text {
            padding: 0 1.25rem;
            margin-bottom: 1.25rem;
        }
        .more_info {
            text-decoration: underline;
            color: red;
        }
    }
    .p-t-b-80 {
        // padding: 0 0 5rem 0;
    }
    .bg-eae7e1 {
        background: #eae7e1;
    }
.content_lunbo_wrap {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 6rem;
    .content_lunbo_box {
        position: relative;
        height: 458px;
        transition: 1s ease-in-out;
        li {
            float: left;
            .content_lunbo_item_insideBox {
                width: calc(100% - 1.875rem);
                height: 438px;
                margin: 0 auto;
                padding-top: 12px;
                background-color: #CE232A;
                position: relative;
                img {
                    display: block;
                    position: absolute;
                    top: 12px;
                    left: 0;
                    width: 100%;
                    height: 438px;
                    -o-object-fit: cover;
                    object-fit: cover;
                    -o-object-position: 50% 60%;
                    object-position: 50% 60%;
                    overflow-clip-margin: content-box;
                    overflow: clip;
                }
                &::after {
                    display: block;
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0.8;
                    background-image: linear-gradient(to top, hsl(0, 0%, 0%) 0%, hsla(0, 0%, 0%, 0.9477587464) 6.36%, hsla(0, 0%, 0%, 0.8863556851) 12.99%, hsla(0, 0%, 0%, 0.8173432945) 19.85%, hsla(0, 0%, 0%, 0.7422740525) 26.91%, hsla(0, 0%, 0%, 0.6627004373) 34.12%, hsla(0, 0%, 0%, 0.5801749271) 41.46%, hsla(0, 0%, 0%, 0.49625) 48.88%, hsla(0, 0%, 0%, 0.4124781341) 56.34%, hsla(0, 0%, 0%, 0.3304118076) 63.81%, hsla(0, 0%, 0%, 0.2516034985) 71.25%, hsla(0, 0%, 0%, 0.1776056851) 78.63%, hsla(0, 0%, 0%, 0.1099708455) 85.91%, hsla(0, 0%, 0%, 0.0502514577) 93.04%, hsla(0, 0%, 0%, 0) 100%);
                }
                .content_lunbo_item_title {
                    width: 15.625rem;
                    height: 40px;
                    background: #DB0812;
                    position: absolute;
                    bottom: -20px;
                    left: 50%;
                    transform: translateX(-50%);
                    text-align: center;
                    line-height: 40px;
                    font-weight: bold;
                    font-size: 14px;
                    color: #FFFFFF;
                    cursor: pointer;
                    z-index: 99;
                }
                .content_lunbo_item_text {
                    width: calc(100% - 50px);
                    // display: flex;
                    // justify-content: space-between;
                    position: absolute;
                    color: #fff;
                    bottom: 1.25rem;
                    z-index: 999;
                    font-size: 1.875rem;
                    text-transform: uppercase;
                    line-height: 1;
                    font-weight: 200;
                    letter-spacing: 0;
                    left: 50%;
                    transform: translate(-50%, 0);
                    // line-height: 2.5rem;
                    div {
                        margin-bottom: 1.25rem;
                    }
                }
            }
            
        }
    }
    .content_lunbo_operation {
        position: absolute;
        bottom: 1.5rem;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        .content_lunbo_circle_btn_wrap {
            display: flex;
            li {
                width: 10px;
                height: 10px;
                background: #FFFFFF;
                border-radius: 50%;
                margin: 6px;
                cursor: pointer;
                &.content_lunbo_circle_btn_item_active {
                    background: #FF1111;
                }
            }
        }
        .content_lunbo_circle_btn_leftAndRight {
            margin-left: 50px;
            position: relative;
            display: flex;
            align-items: center;
            :where(.css-dev-only-do-not-override-11xg00t).ant-btn.ant-btn-icon-only {
                background: transparent;
                // border-color: #000;
                color: #000;
                &:hover {
                    color: #979593;
                    border-color: #979593;
                }
            }
            :where(.css-dev-only-do-not-override-11xg00t).ant-btn.ant-btn-circle.ant-btn-lg {
                background: transparent;
                border-color: #000;
                color: #000;
                &:hover {
                    color: #979593;
                    border-color: #979593;
                }
            }
        }
    }
}
    .action_model_1 {
        // width: calc(100% - 3.75rem);
        margin: 0 auto 1.25rem;
        background: #ce232a;
        padding-top: .75rem;
        .action_model_1_l {
            width: 100%;
            height: 13.125rem;
            img {
                width: 100%;
                height: 13.125rem;
                object-fit: cover;
            }
        }
        .action_model_1_r {
            height: 20.875rem;
            background: #003366;
            color: #Fff;
        }
        .action_model_1_r_1 {
            color: #ce232a;
            font-weight: bold;
            padding-top: 1.875rem;
            margin-bottom: .9375rem;
        }
        .action_model_1_r_2 {
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: .625rem;
        }
        .action_model_1_r_3 {
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 1.875rem;
        }
        .action_model_1_r_4 {
            margin-bottom: 1.875rem;
            font-size: .75rem;
        }
        .action_model_1_r_5 {
            width: 14.375rem;
            height: 2.5rem;
            line-height: 2.5rem;
            text-align: center;
            outline: none;
            border: none;
            background: #ce232a;
            color: #fff;
        }
    }
    .action_model_2 {
        // width: calc(100% - 3.75rem);
        margin: 0 auto 1.25rem;
        .action_model_2_1 {
            background: #0F9F15;
            color: #fff;
            font-size: .875rem;
            font-weight: 700;
            margin-bottom: 1.25rem;
            padding-top: .75rem;
            position: relative;
            img {
                display: block;
                width: 100%;
                height: 18.125rem;
                object-fit: cover;
            }
            a {
                display: block;
                width: 100%;
                background: #ce232a;
                line-height: 2.5rem;
            }
            &::after {
                display: block;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: calc(100% - 2.5rem);
                opacity: 0.5;
                background-image: linear-gradient(to top, hsl(0, 0%, 0%) 0%, hsla(0, 0%, 0%, 0.9477587464) 6.36%, hsla(0, 0%, 0%, 0.8863556851) 12.99%, hsla(0, 0%, 0%, 0.8173432945) 19.85%, hsla(0, 0%, 0%, 0.7422740525) 26.91%, hsla(0, 0%, 0%, 0.6627004373) 34.12%, hsla(0, 0%, 0%, 0.5801749271) 41.46%, hsla(0, 0%, 0%, 0.49625) 48.88%, hsla(0, 0%, 0%, 0.4124781341) 56.34%, hsla(0, 0%, 0%, 0.3304118076) 63.81%, hsla(0, 0%, 0%, 0.2516034985) 71.25%, hsla(0, 0%, 0%, 0.1776056851) 78.63%, hsla(0, 0%, 0%, 0.1099708455) 85.91%, hsla(0, 0%, 0%, 0.0502514577) 93.04%, hsla(0, 0%, 0%, 0) 100%);
            }
            .action_model_title {
                position: absolute;
                width: 100%;
                text-align: center;
                font-size: 1.5rem;
                font-weight: bold;
                padding-top: 1.5625rem;
                z-index: 99;
            }
        }
        .action_model_2_2 {
            background: #51B9E5;
            color: #fff;
            font-size: .875rem;
            font-weight: 700;
            padding-top: .75rem;
            position: relative;
            &::after {
                display: block;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: calc(100% - 2.5rem);
                opacity: 0.5;
                background-image: linear-gradient(to top, hsl(0, 0%, 0%) 0%, hsla(0, 0%, 0%, 0.9477587464) 6.36%, hsla(0, 0%, 0%, 0.8863556851) 12.99%, hsla(0, 0%, 0%, 0.8173432945) 19.85%, hsla(0, 0%, 0%, 0.7422740525) 26.91%, hsla(0, 0%, 0%, 0.6627004373) 34.12%, hsla(0, 0%, 0%, 0.5801749271) 41.46%, hsla(0, 0%, 0%, 0.49625) 48.88%, hsla(0, 0%, 0%, 0.4124781341) 56.34%, hsla(0, 0%, 0%, 0.3304118076) 63.81%, hsla(0, 0%, 0%, 0.2516034985) 71.25%, hsla(0, 0%, 0%, 0.1776056851) 78.63%, hsla(0, 0%, 0%, 0.1099708455) 85.91%, hsla(0, 0%, 0%, 0.0502514577) 93.04%, hsla(0, 0%, 0%, 0) 100%);
            }
            .action_model_title {
                position: absolute;
                width: 100%;
                text-align: center;
                font-size: 1.5rem;
                font-weight: bold;
                padding-top: 1.5625rem;
                z-index: 99;
            }
            a {
                display: block;
                width: 100%;
                background: #ce232a;
                line-height: 2.5rem;
            }
            img {
                display: block;
                width: 100%;
                height: 18.125rem;
                object-fit: cover;
            }
        }
    }
    .content_wrap_header {
        height: 12.5rem;
        position: relative;
        margin-bottom: 3rem;
        img.content_wrap_header_bg {
            width: 100%;
            height: 12.5rem;
            object-fit: cover;
        }
        .content_wrap_header_logo {
            position: absolute;
            width: 10rem;
            height: 6rem;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 50%) scale(0.35);
        }
    }
    .content_wrap {
        .content__box {
            text-align: center;
            width: calc(100% - 1.875rem);
            margin: 0 auto;
        }
    }
    .classical_model {
        padding-top: 1.25rem;
        .classical_model_1 {
            position: relative;
            margin-bottom: 1.25rem;
            img {
                width: 100%;
                height: 20.3125rem;
                object-fit: cover;
            }
            .classical_model_1_title {
                position: absolute;
                width: 10rem;
                height: 2.5rem;
                background: #ce232a;
                color: #fff;
                font-weight: bold;
                font-size: .875rem;
                line-height: 2.5rem;
                left: 50%;
                top: 0;
                transform: translate(-50%, -50%);
            }
        }
        .classical_model_2 {
            .classical_model_2_item_1 {
                background: #003366;
                margin-bottom: 1.25rem;
                button {
                    background: #4C7094;
                }
            }
            .classical_model_2_item_2 {
                background: #ce232a;
                button {
                    background: #DD656A;
                }
            }
            .classical_model_2_item  {
                padding: 1.875rem 0;
                color: #fff;
                .classical_model_2_item_content1 {
                    font-size: 1.5rem;
                    font-weight: bold;
                    line-height: 2.5rem;
                    letter-spacing: -1px;
                }
                .classical_model_2_item_content2 {
                    margin: 0 auto;
                    width: 2.5rem;
                    height: .3125rem;
                    background: #FFFFFF;
                    margin: 1.875rem auto;
                }
                .classical_model_2_item_content3 {
                    margin-bottom: 1.25rem;
                }
                button {
                    width: 12.5rem;
                    height: 2.5rem;
                    outline: none;
                    border: none;
                    color: #fff;
                }
            }
        }
    }
    .social_media {
        background: #f2f0ec;
        padding: 2.5rem .9375rem;
        .social_media_title1 {
            font-size: .75rem;
            margin-bottom: .625rem;
        }
        .social_media_title2 {
            color: #ce232a;
            font-weight: bold;
            letter-spacing: -1px;
            font-size: 1.5rem;
            margin-bottom: 1.25rem;
        }
        .social_media_menu {
            display: flex;
            border-bottom: 1px solid #003366;
            li {
                // flex: 1;
                margin-right: 2.5rem;
                color: #003366;
                font-weight: 700;
                line-height: 2.25rem;
            }
        }
        .social_media_video_title {
            line-height: 3.75rem;
            font-size: .75rem;
        }
        
    }
    .social_media_video {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        li {
            width: calc(50% - .4688rem);
            height: 11.5rem;
            margin: 0 .9375rem .9375rem 0 ;
            position: relative;
            &:nth-child(2n) {
                margin-right: 0;
            }
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}